import { mapGetters } from "vuex";
import moment from "moment";
import redirectToCourseDetail from "@/core/mixins/redirectToCourseDetail.js";

export default {
  mixins: [redirectToCourseDetail],
  data() {
    return {
      isTitleDisplay: false,
      tabIndex: 0,
      recommendedCourses: [],
      placeholderImage: ""
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedCourse",
      "allConfig",
      "language",
      "currentServerTime",
      "getCurrentSection"
    ]),
    modules() {
      let arr = this.getSelectedCourse.sections.map((obj, index) => ({
        ...obj,
        isOpen: this.getCurrentSection
          ? this.getCurrentSection.id === obj.id
          : index === 0
          ? true
          : false
      }));
      return arr;
    },

    allSequences() {
      let arr = [];
      this.getSelectedCourse.sections.forEach(m => {
        m.sequences.forEach(s => {
          arr.push(s);
        });
      });
      return arr;
    },
    hasPassedEnrollmentDate() {
      return moment(this.currentServerTime).isAfter(
        moment(this.getSelectedCourse.enrollment_ends_at)
      );
    },
    isCourseStarted() {
      return this.getSelectedCourse.sections.some(a => a.resumeBlock);
    },

    isCourseEnded() {
      return moment(this.currentServerTime).isAfter(
        moment(this.getSelectedCourse.course_ends_at)
      );
    },
    hasFutureEnrollmentDate() {
      if (this.getSelectedCourse.enrollment_starts_at) {
        return moment(this.getSelectedCourse.current_server_time).isBefore(
          moment(this.getSelectedCourse.enrollment_starts_at)
        );
      } else {
        return false;
      }
    },
    hasFutureStartDate() {
      return moment(this.getSelectedCourse.current_server_time).isBefore(
        moment(this.getSelectedCourse.course_starts_at)
      );
    },
    tooltipData() {
      if (this.isCourseEnded) {
        return this.$i18n.t("course.reached_course_end_date");
      } else {
        return `
            ${
              this.getSelectedCourse.can_enroll
                ? this.$i18n.t("course.enrollments_closed")
                : this.$i18n.t("course.enroll_through_invitation")
            }`;
      }
    },
    enrollSoonTooltipData() {
      let date = this.getSelectedCourse.enrollment_starts_at
        ? moment(this.getSelectedCourse.enrollment_starts_at).format("DD/MM/YY")
        : "";
      return `${this.$i18n.t("course.schedule_to_enrollment") +
        " " +
        date +
        "."}`;
    },
    courseId() {
      return this.$route.params.id;
    }
  },
  watch: {
    getSelectedCourse(v) {
      this.breadcrumbs[this.breadcrumbs.length - 1].text = v.title;
    },
    language(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setBreadCrumbs();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setBreadCrumbs();
    });
  },
  methods: {
    onCard(c) {
      this.navToCourseDetail(c, false);
    },
    visibleHandler(isVisible) {
      if (isVisible) {
        this.isTitleDisplay = false;
      } else {
        this.isTitleDisplay = true;
      }
    },
    isEnrollEnabled(type = "post") {
      let start = this.getSelectedCourse.enrollment_starts_at,
        end = this.getSelectedCourse.enrollment_ends_at,
        bool = false;
      if (!this.getSelectedCourse.can_enroll && type === "post") {
        bool = false;
      } else {
        if (!start && !end) {
          if (
            moment(this.currentServerTime).isAfter(
              moment(this.getSelectedCourse.course_ends_at)
            )
          ) {
            bool = false;
          } else {
            bool = true;
          }
        } else if (start && !end) {
          bool = moment(this.currentServerTime).isSameOrAfter(moment(start));
        } else if (!start && end) {
          bool = moment(this.currentServerTime).isSameOrBefore(moment(end));
        } else {
          bool =
            moment(this.currentServerTime).isSameOrAfter(moment(start)) &&
            moment(this.currentServerTime).isSameOrBefore(moment(end));
        }
      }
      return bool;
    },
    setBreadCrumbs() {
      this.breadcrumbs = [
        {
          icon: require("@/assets/images/courseware/learning-interest.svg"),
          text: this.$i18n.t("general.menu.discovery"),
          to: { name: "SearchCourses" }
        },
        {
          icon: null,
          text: this.getSelectedCourse.title
            ? this.getSelectedCourse.title
            : "",
          active: true
        }
      ];
    }
  }
};
